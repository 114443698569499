import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import GridDelux from '../code/grid-delux';
import Header from '../components/header';
import Seo from '../components/seo';

const NewPage = ({
  data,
  pageContext: { nextPagePath, previousPagePath, pageNumber },
}) => {
  const posts = data.allWpPost;

  const title = pageNumber === 1 ? '' : `Page ${pageNumber}`;

  const loadingGrid = React.createElement(
    'div',
    { className: 'the-loader', key: 1 },
    'Loading...'
  );
  const [grid, setGrid] = useState(loadingGrid);

  useEffect(() => {
    setGrid(<GridDelux dataStream={posts} />);
  }, [posts]);

  return (
    <>
      <Seo title2={title} />
      <Header siteTitle="MrJane" />
      {grid}
      <div className="pagination">
        <Link
          className={'pagination-prev ' + (previousPagePath ? '' : 'disabled')}
          to={previousPagePath ? previousPagePath : '#'}
        >
          Previous page
        </Link>
        <Link
          className={'pagination-next ' + (nextPagePath ? '' : 'disabled')}
          to={nextPagePath ? nextPagePath : '#'}
        >
          Next page
        </Link>
      </div>
    </>
  );
};

export default NewPage;

export const pageQuery = graphql`
  query gomiz($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        featuredImage {
          node {
            mediaDetails {
              height
              width
            }
            sourceUrl
            localFile {
              childImageSharp {
                id
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 90
                  blurredOptions: {}
                )
              }
            }
          }
        }
        title
        id
      }
    }
  }
`;
